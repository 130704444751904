<template>
  <div>
    <validation-observer v-slot="{ invalid }">
      <b-row>
        <b-col class="settings-title" cols="10">
          {{ $t('views.top-bar.settings.general-informations.complex.title') }}
        </b-col>
        <b-col v-if="!editMode" align="right" cols="2">
          <i
            @click="editMode = !editMode"
            class="d-icon-gray icofont icofont-ui-edit pointer"
          />
        </b-col>
        <b-col v-else align="right" cols="2">
          <i
            @click="onSave(invalid)"
            class="d-icon-gray btn-check-size icofont icofont-check pointer"
          />
          <i
            @click="onAbort"
            class="d-icon-gray btn-close-size icofont icofont-close pointer"
          />
        </b-col>
      </b-row>
      <complex-details
        :validation="validation"
        :club="club"
        :edit-mode="editMode"
        @on:logo-updated="reloadClubInstance"
      />
    </validation-observer>
  </div>
</template>
<script>
import {SUCCESS} from "@plugins/flash";
import {putClub} from "@api/doinsport/services/club/club.api";

export default {
  props: {
    club: {
      type: Object,
      default: () => {
      },
    },
    validation: {
      type: Object,
      default: () => require('@validation/entities/doinsport/Club.json'),
    },
  },
  data: () => ({
    editMode: false
  }),
  components: {
    ComplexDetails: () => import('@form/settings/complex-informations/DetailForm')
  },
  computed: {
    getImgDim() {
      const width = this.$store.getters["layout/getInnerWidth"];

      if (width < 767) {
        return '110';
      }
      return '145'
    },
  },
  methods: {
    reloadClubInstance() {
      this.$emit('on:reload-club');
      this.$store.dispatch('auth/getSelectedClub');
    },
    onSave(invalid) {
      if (invalid) {
        this.$flash(this.$t('general.actions.error'), this.$t('validation.check-fields'));
      } else {
        putClub(this.club.id, {
          name: this.club.name,
          description: this.club.description,
          city: this.club.city,
          address: this.club.address,
          zipCode: this.club.zipCode,
          generalTermsUrl: this.club.generalTermsUrl,
          internalRulesUrl: this.club.internalRulesUrl,
        })
          .finally(() => {
            this.$emit('on:reload-club');
            this.$store.dispatch('auth/getSelectedClub');
            this.$flash(null, this.$t('general.actions.success-update'), 5000, SUCCESS);
          })
        ;
        this.editMode = !this.editMode;
      }
    },
    onAbort() {
      this.editMode = !this.editMode;
      this.$emit('on:reload-club');
    },
    getCols(x) {
      const width = this.$store.getters["layout/getInnerWidth"];

      if (width < 1967 && width >= 767) {
        return 3
      } else if (width < 767) {
        return 12;
      } else {
        return x;
      }
    },
  },
}
</script>
<style scoped>
.img-margins {
  margin-top: 20%;
}

.btn-close-size {
  font-size: 20px;
}

.btn-check-size {
  font-size: 22px;
}
</style>
